import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import useAxios from "axios-hooks";
import {API_URL} from "../../config";
import {Badge, Box, Grow, Stack} from "@mui/material";
import {Deco} from "../Home";
import tramboostTheme from "../../themes/tramboostTheme";
import visualTodo from "../../assets/images/tramboost/loghi.png";

const LastFoto = () => {
    const navigate = useNavigate()

    const [state, setState] = useState({
        count: 0,
        timer: null,
        lastPic: null
    })

    const [{data: image, loading, error}, refetch] = useAxios(`${API_URL}photo${('/' + state?.lastPic) || ''}/approved`,
        {manual: true})

    useEffect(() => {
        refetch()

        const interval = setInterval(() => {
            //console.log('This will run every 2 second!');
            refetch()
        }, 12000);
        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        if(image) {
            setState({...state, lastPic: image.id})
        }
    }, [image])

    return (
        <Stack mt={6} height={'90vh'} justifyContent={'center'} alignItems={'center'}>
            <Deco/>
            {image && <Grow in={true} exit={true} timeout={1000}>
                <Stack alignItems={'center'} justifyContent={'center'} height={'100%'} margin={'auto'}>
                    <Stack p={1} mt={2}
                           margin={'auto'}
                           border={'6px solid' + tramboostTheme.palette.primary.main}
                           borderRadius={'2rem'}
                           sx={{
                               boxShadow: `20px 20px 0px 0px ${tramboostTheme.palette.primary.main}`
                           }}
                    >
                        <Badge anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                               badgeContent={
                                   <Grow in={true} exit={true} timeout={1000}>
                                       <Box display={'flex'}>
                                           <img className={'el2'} src={visualTodo} alt="visual"
                                                style={{
                                                    width: '50%',
                                                    margin: 'auto',
                                                    rotate: '-30deg',
                                                    //padding: '16px',
                                                    borderRadius: '2rem',
                                                    //background: tramboostTheme.palette.primary.main
                                                }}/>
                                       </Box>
                                   </Grow>
                               }>
                            <img className={'el2'}
                                 src={`https://storage.googleapis.com/tramboosto/${image.filename}`}
                                 alt="randimg"
                                 style={{
                                     //marginTop: '8px',
                                     //maxWidth: '230px',
                                     //maxHeight: '350px',
                                     objectFit: 'cover',
                                     borderRadius: '2rem'
                                 }}/>
                        </Badge>

                    </Stack>
                </Stack>
            </Grow>}
        </Stack>
    );
}

export default LastFoto