import React, {useEffect} from "react";
import {Box} from "@mui/material";
import useAxios from "axios-hooks";
import {API_URL} from "../../config";
import {WordcloudContent} from "../../components/visual/content/WordcloudContent";
import axios from "axios";

export const Wordcloud = () => {

    const [{data, loading, error}, refetch] = useAxios(`${API_URL}wordcloud`)


    useEffect(() => {
        refetch()
        window.clearInterval()
        let intervalId = window.setInterval(refetch,4000)
        return ()=> {
            window.clearInterval(intervalId)
        }
    },[])

    return (
        <Box sx={{width:'100%', height:'100vh'}}>
            <WordcloudContent result={data}/>
        </Box>
    )
}
