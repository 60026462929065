import {createTheme, responsiveFontSizes} from "@mui/material";

let tramboostTheme = createTheme({
    typography: {
        fontFamily: ['Gotham']
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: {variant: 'neumorphic'},
                    style: {
                        height: '50px',
                        width:'100%',
                        fontSize: '14px',
                        fontWeight: 'bolder',
                        border:'none',
                        borderRadius: '1.8rem',
                        borderWidth: '3px',
                        //background: 'linear-gradient(90deg, #f38a50 0%, #2f4b9b)',
                        //background: 'linear-gradient(90deg, #a0b4eb3d -10%, #2f4b9b)',
                        //background: '#56558b',
                        //background: '#fda773',
                        background: 'linear-gradient(90deg, #fda773 10%, #ED3C9AC5)',
                        //background: '#E77535',
                        boxShadow: '4px 4px 10px 0px #f28e59, ' +
                            '-4px 2px 8px hwb(224deg 20% 40% / 50%), ' +
                            '6px 6px 8px rgb(255 255 255 / 8%), ' +
                            '6px 6px 10px rgb(0 0 0 / 15%)',
                        "&:active": {
                            boxShadow: 'inset -2px -2px 6px rgba(231, 117, 53, 0.65),\n' +
                                '    inset -2px -2px 4px rgba(255, 255, 255, .65),\n' +
                                '    inset 2px 2px 2px rgba(255, 255, 255, .075),\n' +
                                '    inset 2px 2px 4px rgba(47, 75, 155, 0.4);'
                        },
                        "&:disabled": {
                            background: 'linear-gradient(90deg, #fda773 10%, #ED3C9AC5)',
                            opacity: 0.7,
                            boxShadow: 'inset -2px -2px 6px rgb(231 117 53 / 65%), inset -2px -2px 4px #e7c4b1, inset 2px 2px 2px rgb(255 255 255 / 8%), inset 2px 2px 4px rgb(47 75 155 / 40%)'
                            //background: 'linear-gradient(90deg, rgba(242,137,80,0.5) 0%, rgba(47,76,156,0.5))',
                        }
                    }
                },
                {
                    props: {variant: 'selected-neumorphic'},
                    style: {
                        height: '60px',
                        width:'100%',
                        fontSize: '16px',
                        fontWeight: 'bolder',
                        borderRadius: '1.8rem',
                        //border: '1px solid #2f4b9b',
                        //background: '#2f4b9b',
                        boxShadow: 'inset -2px -2px 6px rgba(231, 117, 53, 0.65),\n' +
                            '    inset -2px -2px 4px rgba(255, 255, 255, .5),\n' +
                            '    inset 2px 2px 2px rgba(255, 255, 255, .075),\n' +
                            '    inset 2px 2px 4px rgba(47, 75, 155, 0.4);',
                        "&:disabled": {
                            background: 'rgba(47,76,156,0.22)',
                            boxShadow: 'inset -2px -2px 6px rgb(231 117 53 / 65%), ' +
                                'inset -2px -2px 4px #f38a50, ' +
                                'inset 2px 2px 2px rgb(255 255 255 / 8%), ' +
                                'inset 2px 2px 4px rgb(47 75 155 / 40%)'
                        }
                    }
                }
            ]
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#2f4b9b'
                }
            }
        }
    },
    palette: {
        mode:'dark',
        primary:{
            main:'#ED3C9A',
        },
        secondary: {
            main: '#fda773',
        },
        text: {
            primary: '#eceff1',
        },
        background:{
            default: '#f87f83'
        }
    }
})

tramboostTheme = responsiveFontSizes(tramboostTheme)

export default tramboostTheme
