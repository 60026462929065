import React, {useState} from "react";
import {ControlLayout} from "../../layouts/ControlLayout";
import {
    Box,
    Button,
    CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
    Divider,
    FormControlLabel,
    FormGroup, Stack,
    Switch,
    Tab,
    Typography
} from "@mui/material";
import {Delete, Refresh} from "@mui/icons-material";
import useAxios from "axios-hooks";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {ListaMessaggi} from "../../components/control/messaggi/ListaMessaggi";
import {API_URL} from "../../config";
import {MessaggiApprovati} from "../../components/control/messaggi/MessaggiApprovati";
import {MessaggiDaApprovare} from "../../components/control/messaggi/MessaggiDaApprovare";
import {MessaggiRifiutati} from "../../components/control/messaggi/MessaggiRifiutati";
import {useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../../firebase/clientApp";
import axios from "axios";

const messaggi = [
    {
        id:1,
        messaggio:"Primo messaggio bellissimo da approvare",
        stato:"daApprovare"
    },
    {
        id:2,
        messaggio:"secondo messaggio bellissimo da approvare",
        stato:"daApprovare"
    },
    {
        id:3,
        messaggio:"terzo messaggio bellissimo da approvare",
        stato:"daApprovare"
    },
    {
        id:4,
        messaggio:"Primo messaggio bellissimo approvato",
        stato:"approvato"
    },
    {
        id:5,
        messaggio:"secondo messaggio bellissimo approvato",
        stato:"approvato"
    },
    {
        id:6,
        messaggio:"terzo messaggio bellissimo approvato",
        stato:"approvato"
    },
    {
        id:7,
        messaggio:"Primo messaggio bellissimo rifiutato",
        stato:"rifiutato"
    },
    {
        id:8,
        messaggio:"secondo messaggio bellissimo rifiutato",
        stato:"rifiutato"
    },
    {
        id:9,
        messaggio:"terzo messaggio bellissimo rifiutato",
        stato:"rifiutato"
    },
]

export const ControlMessaggi = () => {
    const [active, activeLoading, activeError] = useDocument(firestore.doc('test/sezioniAperte'))
    const [value, setValue] = React.useState('1');
    const [open, setOpen] = useState(false)

    const [{data, loading, error}, refetch] = useAxios(`${API_URL}message/toApprove`)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleActive = async () => {
        await axios.post(`${API_URL}actions`, {action: 'messaggi', value: !active.data().messaggi})
            .finally(() => refetch())
    }

    const reset = async () => {
        await axios.post(`${API_URL}clear/messaggi`, {})
            .then(() => setOpen(!open))
            .finally(() => refetch())
    }

    return (
        <ControlLayout>
            <Dialog open={open} onClose={() => setOpen(!open)}>
                <DialogContent sx={{backgroundColor:'#121212'}}>
                    <DialogTitle>
                        Vuoi eliminare tutti i messaggi?
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => setOpen(!open)} variant={"outlined"}>no</Button>
                        <Button onClick={reset} variant={"contained"} color={"error"}>Si</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Typography variant={'h4'} fontWeight={"bold"}>Messaggi</Typography>
            <Divider sx={{my:2}}/>
            <Box sx={{pb:2}}>
                <Typography variant={"h6"}>
                    Attiva/disattiva sezione
                </Typography>
                {activeLoading ? <CircularProgress/> : <FormGroup>
                    <FormControlLabel control={<Switch checked={active?.data()?.messaggi}
                                                       onChange={(e) => handleActive(e.target.checked)}/>}
                                      label={active?.data()?.messaggi ? 'Invio messaggi APERTO' : "Invio messaggi CHIUSO"}/>
                </FormGroup>}
            </Box>
            <Divider/>
            <Typography variant={"h6"} sx={{pt:2}}>
                Moderazione
            </Typography>
            <Stack sx={{py: 1}} direction={"row"} justifyContent={"space-between"}>
                <Button onClick={() => refetch()} variant={"contained"} startIcon={<Refresh/>}>Ricarica</Button>
                <Button onClick={() => setOpen(!open)} variant={"outlined"} color={"error"} startIcon={<Delete/>}>Reset risultati</Button>
            </Stack>
            <Box>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Da approvare" value="1" />
                            <Tab label="Approvati" value="2" />
                            <Tab label="Rifiutati" value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <MessaggiDaApprovare data={data} loading={loading} refetch={refetch}/>
                    </TabPanel>
                    <TabPanel value="2">
                        <MessaggiApprovati/>
                    </TabPanel>
                    <TabPanel value="3">
                        <MessaggiRifiutati/>
                    </TabPanel>
                </TabContext>

            </Box>
        </ControlLayout>
    )
}
