import React, {useEffect} from "react";
import {ListaMessaggi} from "./ListaMessaggi";
import useAxios from "axios-hooks";
import {API_URL} from "../../../config";
import {Box, CircularProgress} from "@mui/material";

export const MessaggiApprovati = ({domandeCartomante = false}) => {
    const [{data, loading, error},refetch] = useAxios(`${API_URL}${domandeCartomante ? 'domandeCartomante' : 'message'}/approved`)

    useEffect(() => {
        refetch()
    },[])

  return(
      <Box>
          {loading ? <CircularProgress/> : <ListaMessaggi domandeCartomante={true} stato={'approvati'} messages={data} refetch={refetch}/>}
      </Box>
  )
}
