import React, {useEffect} from "react";
import {ListaMessaggi} from "./ListaMessaggi";
import useAxios from "axios-hooks";
import {API_URL} from "../../../config";
import {Box, Button, CircularProgress} from "@mui/material";

export const MessaggiRifiutati = ({domandeCartomante = false}) => {
    const [{data, loading, error},refetch] = useAxios(`${API_URL}${domandeCartomante ? 'domandeCartomante' : 'message'}/blocked`)

    useEffect(() => {
        refetch()
    },[])

    return(
        <Box>
            {loading ? <CircularProgress/> : <ListaMessaggi domandeCartomante={true} stato={'rifiutati'} messages={data} refetch={refetch}/>}
        </Box>
    )
}
