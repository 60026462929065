import './App.css';
import {Badge, Box, createTheme, CssBaseline, Grow, Stack, ThemeProvider} from "@mui/material";
import {Route, Routes, useNavigate} from "react-router-dom";
import {Deco, Home} from "./pages/Home";
import {ControlFoto} from "./pages/control/ControlFoto";
import {ControlMessaggi} from "./pages/control/ControlMessaggi";
import {ControlQuiz} from "./pages/control/ControlQuiz";
import tramboostTheme from "./themes/tramboostTheme";
import React, {useEffect, useState} from "react";
import {Wordcloud} from "./pages/visual/Wordcloud";
import {FotoWall} from "./pages/visual/FotoWall";
import {Sondaggio} from "./pages/visual/Sondaggio";
import {ControlDomandeCartomante} from "./pages/control/ControlDomandeCartomante";
import useAxios from "axios-hooks";
import {API_URL} from "./config";
import visualTodo from "./assets/images/tramboost/loghi.png";
import {Messaggi} from "./pages/visual/Messaggi";
import LastFoto from "./pages/visual/LastFoto";
import {LastMessagge} from "./pages/visual/LastMessagge";

function App() {
    const theme = createTheme({
        palette: {
            background: {
                default: '#0e0e0e'
            }
        }
    });
    return (
        <div className="App">
            <ThemeProvider theme={tramboostTheme}>
                <CssBaseline/>
                <Routes>
                    <Route path={'/*'} element={<Home/>}/>
                    <Route path={'/control/domandeCartomante'} element={<ControlDomandeCartomante/>}/>
                    <Route path={'/control/messaggi'} element={<ControlMessaggi/>}/>
                    <Route path={'/control/foto'} element={<ControlFoto/>}/>
                    <Route path={'/control/quiz'} element={<ControlQuiz/>}/>
                    <Route path={'/visual/messaggiRandom'} element={<Messaggi/>}/>
                    <Route path={'/visual/messaggi'} element={<LastMessagge/>}/>
                    <Route path={'/visual/fotowall'} element={<FotoWall/>}/>
                    <Route path={'/visual/foto'} element={<LastFoto/>}/>
                    <Route path={'/visual/wordcloud'} element={<Wordcloud/>}/>
                    <Route path={'/visual/sondaggio/:id'} element={<Sondaggio/>}/>
                </Routes>
            </ThemeProvider>
        </div>
    );
}

export default App;
