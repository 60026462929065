import {alpha, Box, Grow, IconButton, Stack} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";
import flamingo from "../../assets/images/tramboost/flamingo.png";
import logoPlesh from "../../assets/images/logo_plesh.png";
import React from "react";
import {useNavigate} from "react-router-dom";
import tramboostTheme from "../../themes/tramboostTheme";
import {emojisplosion} from "emojisplosion";
import visual from "../../assets/images/tramboost/loghi.png";
import {Deco} from "../Home";
import visualTramboosto from "../../assets/images/tramboost/tramboosto.png";

const emojis = ['🦩','🦩','🦩','🦩', '✨','✨','✨', '🎉', '🍆', '🍑', '🔥', '🌺', '🌼', '🚋', '🍾', '🍻', '😇', '🤯',
    '😎', '❤️', '‼️', '👽', '👾', '🤙', '🔝', '🤖' , '🌱' , '🌵']

export function emojiSplashhh(e) {
    emojisplosion({
        emojiCount: 7,
        physics: {gravity: 0.2},
        emojis: [emojis[Math.floor(Math.random()*emojis.length)]],
        position: () => ({
            x: e.clientX,
            y: e.clientY,
        }),
    })
}

const CustomHeader = ({disableBack = false, plesh = true}) => {
    const navigate = useNavigate()
    function handleBack() {
        navigate('/')
    }

    return (<Box mt={1} px={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            {!disableBack ? <IconButton color={'primary'} onClick={handleBack}>
                <ArrowBack fontSize={'large'}/>
            </IconButton> : null}
            <Grow in={true} exit={true} timeout={1000}>
                <img id={'clapButton'} src={flamingo} className="App-logo" alt="logo" style={{
                    maxWidth: '62px',
                    padding: 8,
                    width: '37%',
                    marginTop: 8,
                    zIndex: 1000,
                    borderRadius: '50%',
                    boxShadow: `inset ${alpha(tramboostTheme.palette.primary.main, 0.8)} -19px -9px 20px 0px,
                    ${alpha(tramboostTheme.palette.primary.main, 0.7)} 0px 2px 17px 0px`,
            }} onClick={emojiSplashhh}/>
            </Grow>
            <Grow in={true} exit={true} timeout={1000}>
                <Box display={'flex'}>
                    <img className={'el2'} src={visual} alt="visual"
                         style={{maxWidth: '250px', width: '35%', marginLeft: 'auto'}}/>
                </Box>
            </Grow>
        </Box>
        {/*<Box onClick={() => {
            window.open("https://plesh.co/", '_blank');
        }} mt={1} flex={1} display={'flex'} flexDirection={"column"} alignItems={'flex-end'} justifyContent={'baseline'}>
            {plesh && <img src={logoPlesh} className="App-logo" alt="logo"
                  style={{maxWidth: '100px', width: '42%', marginRight: '6%', filter: "brightness(0) invert(1)"}}/>}
        </Box>*/}
    </Box>)
}

export default CustomHeader