import React, {useEffect, useState} from "react";
import {Box, Grow, Stack, Typography} from "@mui/material";
import tramboostTheme from "../../themes/tramboostTheme";
import useAxios from "axios-hooks";
import {API_URL} from "../../config";
import {Deco} from "../Home";
import {useParams} from "react-router-dom";
import {useQuery} from "../../utils/useQuery";

export const LastMessagge = () => {
    //const [messagePlaying, messageLoading, messageError] = useDocument(firestore.doc('test/message/'))
    const query = useQuery()
    document.body.style.backgroundColor = query.get("bg") === '1' ? tramboostTheme.palette.background.default : "black"

    const [state, setState] = useState({
        lastMsg: null
    })

    const [{data: messages, loading, error}, refetch] = useAxios(`${API_URL}message/lastMessage`)

    useEffect(() => {
        const interval = setInterval(() => {
            //console.log('This will run every 2 second!');
            refetch()
        }, 15000);
        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        if(messages)
            setState({...state, lastMsg: messages.id})
    }, [messages])

    return (<>
            {query.get("bg") === '1' ? <Deco/> : query.get("bg") === '0' ? null : null}
            {!loading && messages?.message ?
                <Grow in={true} exit={true} timeout={900}>
                    <Stack mt={3} height={'70vh'} justifyContent={'center'} alignItems={'center'}>
                        <Box marginX={'5%'}>
                            <div className={"triangoloEq"}></div>
                            <Box py={5} px={2} borderRadius={'2rem'}
                                 sx={{
                                     //background: tramboostTheme.palette.text.primary,
                                     background: 'white',
                                     color: tramboostTheme.palette.primary.main,
                                     boxShadow: `11px 11px 10px 0px ${tramboostTheme.palette.primary.main}`
                                 }} maxWidth={'1000px'} /*maxHeight={'490px'}*/>
                                <Typography variant={'h2'} color={tramboostTheme.palette.primary.main}
                                            fontWeight={'bold'}
                                            textAlign={'center'}>
                                    {messages?.message}
                                </Typography>
                            </Box>
                        </Box>
                    </Stack>
                </Grow>
                : null}
        </>
    )
}
