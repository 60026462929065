export const API_URL = "https://tramboosto-api-nmjsm43u4q-nw.a.run.app/"
//export const API_URL = "http://localhost:8080/"

export const COLORI = ['#9bdabe', '#ffffff', '#667ec4']

export const MAX_PHOTOS = 81

export const MAX_CHAR = 100;

export const PERCENTAGE_COLOR = '#000'
