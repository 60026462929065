import React from "react";
import {Box, Typography} from "@mui/material";
import logoPlesh from "../../assets/images/logo_plesh.png";

const PoweredBy = () => {
    return <Box mt={6} onClick={() => window.open("https://plesh.co/", '_blank')}
                display={"flex"} justifyContent={"center"}>
        <Typography variant={'caption'}>Powered by</Typography>
        <img src={logoPlesh} className="App-logo" alt="logo" style={{
            marginLeft: "2%",
            width: "18%",
            maxWidth: '350px',
            objectFit: 'contain',
            filter: "brightness(0) invert(1) opacity(0.9)"
        }}/>
    </Box>;
}

export default PoweredBy