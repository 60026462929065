import React, {useState} from "react";
import {Avatar, Backdrop, Badge, Box, Button, CircularProgress, Grow, Stack} from "@mui/material";
import tramboostTheme from "../../themes/tramboostTheme";
import {CameraEnhance, ChangeCircle} from "@mui/icons-material";
import {motion, useMotionValue} from "framer-motion";
import useAxios from "axios-hooks";
import SuccessCheck from "./SuccessCheck";
import {API_URL} from "../../config";
import PoweredBy from "./PoweredBy";

const UserFoto = ({}) => {
    let progress = useMotionValue(90)

    const [foto, setFoto] = useState(null)
    const [url, setUrl] = useState('')

    const handleCompileFoto = (e) => {
        const [f] = e.target.files
        if(f) {
            setFoto(f)
            setUrl(URL.createObjectURL(f))
        }
    }

    const [{data: fotoUploaded, loading: uploadingFoto, error: errorFoto}, sendFoto] = useAxios({},
        {manual: true}
    )

    const handleSendFoto = async () => {
        const formData = new FormData();
        formData.append('file', foto);
        sendFoto({
            url: `${API_URL}photo`, // TODO: url
            method: "POST",
            data: formData,
        })
            .then((res) => {
                setFoto(null)
                setUrl('')
            })
            .catch((err) => {
                console.log("err:", err)
                setFoto(null)
                setUrl('')
            })
    }

    return (<>
            <Backdrop
                sx={{color: '#f1ebe9', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={uploadingFoto}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Grow in={true} exit={true} timeout={600}>
                <Box display={'flex'}
                     flexDirection={'column'}
                     alignItems={"center"}
                     mt={1}
                     sx={{//position: 'absolute', bottom: '30px', left: 0, right: 0,
                         textAlign: 'center'
                     }}>
                    <form>
                        <Stack width={'100%'} direction={"column"} spacing={2} alignItems={'center'}
                               justifyContent={'center'}>
                            {!uploadingFoto && (fotoUploaded || errorFoto) &&
                                <Box mt={1} width={'50%'} maxWidth={'fit-content'}>
                                    <motion.div
                                        initial={{x: 0}}
                                        animate={{x: 100}}
                                        style={{x: progress}}
                                        transition={{duration: 0.9}}
                                    />
                                    <SuccessCheck progress={progress}/>
                                </Box>}
                            <Box mt={2} mb={2} p={0.5} display={'flex'} justifyContent={'center'} alignItems={'center'}
                                 height={'16.5rem'} width={'100%'} minWidth={'16.5rem'} /*maxWidth={'15.5rem'}*/
                                 sx={{
                                    fontWeight: '600',
                                    borderRadius: '2rem',
                                    border: ('8px dashed ' + tramboostTheme.palette.secondary.main),
                                }} component="label">
                                <Badge badgeContent={!foto ?
                                    <Avatar sx={{ bgcolor: tramboostTheme.palette.text.primary, width: 64, height: 64, opacity: '0.8' }}>
                                        <CameraEnhance color={'text'} sx={{fontSize: '42px'}}/>
                                    </Avatar>
                                    : <ChangeCircle color={'text'} fontSize={'large'}/>}>
                                    <img style={{maxWidth: '13rem', maxHeight: '13rem', borderRadius: '1rem'}} src={url}/>
                                </Badge>
                                <input type="file" accept="image/*" hidden onChange={handleCompileFoto}/>
                            </Box>
                            <Button onClick={handleSendFoto} disabled={!foto}
                                    variant={"neumorphic"}>
                                Invia
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Grow>
        </>
    )
}

export default UserFoto
