import React, {useEffect, useState} from "react";
import {ControlLayout} from "../../layouts/ControlLayout";
import useAxios from "axios-hooks";
import {
    Box,
    Button,
    CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
    Divider,
    FormControlLabel,
    FormGroup,
    Stack,
    Switch,
    Typography
} from "@mui/material";
import {Delete, Refresh} from "@mui/icons-material";
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import {useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../../firebase/clientApp";
import axios from "axios";
import {API_URL, COLORI, PERCENTAGE_COLOR} from "../../config";

export const ControlQuiz = () => {
    const [active, activeLoading, activeError] = useDocument(firestore.doc('test/quiz'))
    const [{data: results, loading, error}, refetch] = useAxios({url: `${API_URL}quiz/${active?.data()?.id}`, method:'GET'}, {manual:true})
    const [open, setOpen] = useState(false)

    useEffect(() => {
        refetch()
    },[active])

    const handleChange = async (event, newAlignment) => {
        //await axios.post(`${API_URL}actions`, {action: 'quiz', value: false})
        await axios.post(`${API_URL}quiz/active`, {id: newAlignment})
        //await axios.post(`${API_URL}actions`, {action: 'quiz', value: true})
    };

    const handleActive = async () => {
        await axios.post(`${API_URL}actions`, {action: 'quiz', value: !active.data().show})
    }

    const reset = async () => {
        await axios.post(`${API_URL}clear/quiz`, {id:active.data().id})
            .then(() => setOpen(!open))
            .finally(() => refetch())
    }

    return (
        <ControlLayout>
            <Dialog open={open} onClose={() => setOpen(!open)}>
                <DialogContent sx={{backgroundColor:'#121212'}}>
                    <DialogTitle>
                        Vuoi eliminare tutti i risultati del quiz corrente?
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => setOpen(!open)} variant={"outlined"}>no</Button>
                        <Button onClick={reset} variant={"contained"} color={"error"}>Si</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Typography variant={'h4'} fontWeight={"bold"}>Quiz</Typography>
            <Divider sx={{my: 2}}/>
            <Box sx={{pb: 2}}>
                <Typography variant={"h6"}>
                    Attiva/disattiva sezione
                </Typography>
                {activeLoading ?
                    <CircularProgress/>
                    :
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch checked={active.data().show}
                                             onChange={(e) => handleActive(e.target.checked)}/>}
                            label={active.data().show ? 'Quiz APERTO' : "Quiz CHIUSO"}/>
                    </FormGroup>}
                <Typography variant={"h6"} sx={{pt: 2}}>
                    Seleziona domanda
                </Typography>
                {activeLoading ?
                    <CircularProgress/>
                    : <ToggleButtonGroup
                        color="primary"
                        value={active.data().id}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                    >
                        <ToggleButton value="A4GPyB6zo33cutW0eMSN">1) Domanda 1 - Coffee first</ToggleButton>
                        <ToggleButton value="CXSlDdiLJMo3pv382XQZ">2) Domanda 2 - Run Baby Run</ToggleButton>
                        <ToggleButton value="tccTes3G0WFx95qiHecy">3) Domanda 3 - Presto!</ToggleButton>
                    </ToggleButtonGroup>}
                <Box mt={2} display={'grid'}>
                    <Typography variant={'caption'}>1) A4GPyB6zo33cutW0eMSN</Typography>
                    <Typography variant={'caption'}>2) CXSlDdiLJMo3pv382XQZ</Typography>
                    <Typography variant={'caption'}>3) tccTes3G0WFx95qiHecy</Typography>
                </Box>
            </Box>
            <Divider/>
            <Typography variant={"h6"} sx={{pt: 2}}>
                Moderazione
            </Typography>
            <Stack sx={{py: 1}} direction={"row"} justifyContent={"space-between"}>
                <Button onClick={() => refetch()} variant={"contained"} startIcon={<Refresh/>}>Ricarica</Button>
                <Button onClick={() => setOpen(!open)} variant={"outlined"} color={"error"} startIcon={<Delete/>}>Reset risultati</Button>
            </Stack>
            <Box sx={{py: 2}}>
                {loading ? <CircularProgress/>
                    :
                    !error &&
                    <Box>
                        {results && Object.keys(results).map((r, i) =>
                            <Box sx={{py: 2}} key={i}>
                                <Box sx={{position:'relative'}}>
                                    <Box sx={{position:'absolute', top:'50%', transform:'translate(0, -50%)', left:'2rem'}}>
                                        <Typography sx={{color:PERCENTAGE_COLOR, fontWeight:'bold', fontSize:'1.2rem'}}>
                                            {results[r] === 0 ? 0 : (100 * (results[r]) / Object.values(results).reduce((partialSum, a) => partialSum + a, 0)) + '%'}
                                        </Typography>
                                    </Box>
                                    <Box sx={{
                                        height: '50px',
                                        width: results[r] === 0 ? 0 : (100 * (results[r]) / Object.values(results).reduce((partialSum, a) => partialSum + a, 0)) + '%',
                                        backgroundColor: COLORI[i],
                                        transition: '1s ease',
                                        borderRadius:'0 1rem 1rem 0'
                                    }}></Box>
                                </Box>
                                <Box sx={{fontSize: '1.2rem', fontWeight: 'bold'}}>
                                    {r}
                                </Box>
                            </Box>
                        )}
                    </Box>
                }
            </Box>
        </ControlLayout>
    )
}
