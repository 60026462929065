import React from "react";
import {Box, Button} from "@mui/material";


export default function CustomButton({label, onClick, icon}) {
    return (
        <Button onClick={onClick} fullWidth
                variant={"neumorphic"}
                //startIcon={icon}
        >
            {icon}
            <Box ml={1.5}>{label}</Box>
        </Button>
    )
}
