import React, {useEffect, useState} from "react";
import useAxios from "axios-hooks";
import {API_URL, MAX_PHOTOS} from "../../config";
import {Box} from "@mui/material";
import "../../Gallery.css"
import {FotoWallContent} from "../../components/visual/content/FotoWallContent";

export const FotoWall = () => {
    const [columns, setColumns] = useState(3);
    const [rows, setRows] = useState(3);

    //const docRef = firestore.collection('test/data/photos').where("approved" , "==", true);
    //const [images, loading, error] = useCollection(docRef)
    const [{data:images, loading, error}, refetch] = useAxios(API_URL+'photo/approved/all')

    const [grid, setGrid] = useState([])

    function shuffle(array) {
        let randomIndex;
        let a = array ? array.sort((a, b) => {
            return a.createdAt < b.createdAt;
        }).slice(0, MAX_PHOTOS) : []

        let currentIndex = a.length

        // While there remain elements to shuffle.
        while (currentIndex !== 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [a[currentIndex], a[randomIndex]] = [
                a[randomIndex], a[currentIndex]];
        }
        return a;
    }

    const getGrid = () => {
        const len = (images?.length > MAX_PHOTOS ? MAX_PHOTOS : images?.length);
        const val = Math.trunc(Math.sqrt(len));
        setColumns(val + 1);

        if ((val + 1) * (val + 1) <= len) {
            setRows(val + 1)
        } else if ((val + 1) * (val) <= len) {
            setRows(val);
        } else {
            setRows(val - 1);
        }

        setGrid(() => shuffle(images))
    }

    useEffect(() => {
        refetch()
        window.clearInterval()
        let intervalId = window.setInterval(() => refetch(), 4000)
        return ()=> {
            window.clearInterval(intervalId)
        }
    },[])

    useEffect(() => {
        if(images) {
            getGrid()
        }
    },[images])

    return (
        <Box>
            <FotoWallContent g={grid} columns={columns} rows={rows}/>
        </Box>
    )
}
