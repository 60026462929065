import React, {useEffect, useState} from "react";
import visualTramboosto from "../assets/images/tramboost/tramboost_sfondo.png";
import {Badge, Box, Button, Grid, Grow, Stack, Typography} from "@mui/material";
import CustomButton from "./user/CustomButton";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../firebase/clientApp";
import CustomHeader, {emojiSplashhh} from "./user/CustomHeader";
import _ from 'lodash'
import tramboostTheme from "../themes/tramboostTheme";
import WrapperInterazione from "./user/WrapperInterazione";
import UserFoto from "./user/UserFoto";
import {CameraEnhance, Flare, Message, Refresh} from "@mui/icons-material";
import sfondo from "../assets/images/tramboost/sfondo2.jpeg";
import {UserMessaggi} from "./user/UserMessaggi";
import useAxios from "axios-hooks";
import {API_URL} from "../config";
import blu from '../assets/images/croce_home/linea blu in alto-01.png'
import rosa from '../assets/images/croce_home/linea rosa in alto-01.png'
import verde from '../assets/images/croce_home/linea verde in alto-01.png'
import {motion} from "framer-motion";

export const Deco = () => {
    const imgs = [
        {img: blu},
        {img: verde},
        {img: rosa},
    ]

    return (<Box sx={{
            position: 'absolute',
            right: 0,
            top: 30,
            opacity: 0.15,
            zIndex: 0
        }}>
            <Grow in={true} exit={true} timeout={1600}>
                <Box position={'relative'}>
                    {imgs.map((obj, index) => (
                        <motion.div key={index} style={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                        }}>
                            <img src={obj.img} width={'750px'} style={{
                                filter: "brightness(0) invert(1) opacity(0.9)"
                            }}/>
                        </motion.div>)
                    )}
                </Box>
            </Grow>
        </Box>
    )
}

const MAX_CLICK = 10;

function HomeUI({pulsantiera: pulsantiAttivi}) {
    const navigate = useNavigate()

    const [state, setState] = useState({
        count: 0,
        timer: null,
        lastPic: null
    })

    const [{data: image, loading, error}, refetch] = useAxios(`${API_URL}photo${('/'+state?.lastPic) || ''}/approved/random`,
        {manual: true})

    useEffect(() => {
        refetch().then((res) => {
            setState({...state, lastPic: res.data.id})
        })
    }, [])

    const enableRandImg = () => {
        return state.count <= MAX_CLICK
    }

    function getOnClick() {
        refetch().then((res) => {
            setState({
                ...state,
                count: state.count + 1,
                lastPic: res.data.id
            })

            if(state.count === MAX_CLICK)
                setTimeout(() => {
                    setState({
                        ...state, count: 0
                    })
                    // 15 minuti
                }, 900000)
        });
    }

    return (
        <>
            <Deco />
            <header className="App-header">
                <CustomHeader disableBack/>
            </header>
            {image && <Grow in={true} exit={true} timeout={1000}>
                <Stack mt={2} alignItems={'center'}>
                    {enableRandImg() ?
                        <Stack p={1} mt={0.5}
                               border={'6px solid' + tramboostTheme.palette.primary.main}
                               borderRadius={'2rem'}
                               sx={{
                                   boxShadow: `20px 20px 0px 0px ${tramboostTheme.palette.primary.main}`
                               }}
                        >
                            {/*<Badge anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                                badgeContent={
                                <Grow in={true} exit={true} timeout={1000}>
                                    <Box display={'flex'}>
                                        <img className={'el2'} src={visualTramboosto} alt="visual"
                                             style={{
                                                 width: '50%',
                                                 margin: 'auto',
                                                 rotate: '-30deg',
                                        }}/>
                                    </Box>
                                </Grow>
                            }>*/}
                                <img className={'el2'}
                                     src={`https://storage.googleapis.com/tramboosto/${image.filename}`}
                                     alt="randimg"
                                     style={{
                                         //marginTop: '8px',
                                         maxWidth: '230px',
                                         maxHeight: '350px',
                                         objectFit: 'cover',
                                         borderRadius: '2rem'
                                     }}/>
                            {/*</Badge>*/}

                        </Stack>
                        : <Box p={4}>
                            <Typography gutterBottom variant={'h4'} fontWeight={'bold'} textAlign={'center'} sx={{
                                marginTop: '30%'
                            }}>
                                Basta stare al telefono, vai a ballare! 🍾
                            </Typography>
                            <Typography variant={'h5'} textAlign={'center'}>
                                Però torna tra un po' per fare altre foto ❤️
                            </Typography>
                        </Box>}
                </Stack>
            </Grow>}
            <Grow in={true} exit={true} timeout={600}>
                <Grid px={3} mb={2} container spacing={1.5} alignItems={'center'} justifyContent={'center'}
                      sx={{position: 'absolute', bottom: '50px', left: 0, right: 0}}>
                    <Button id={'clapButton'} sx={{marginBottom: '1%'}} variant={'contained'}
                            onClick={enableRandImg() ? getOnClick : emojiSplashhh} disabled={loading}
                            endIcon={<Refresh fontSize={'large'}/>}>
                        VEDI FOTO RANDOM
                    </Button>
                    {/*<Typography variant={'h4'} fontWeight={'bold'}>Invia...</Typography>*/}
                    {
                        pulsantiAttivi.map((pulsante, i) => {
                                return !pulsante.hidden &&
                                    <Grow key={i} in={true} exit={true} timeout={600 * (i + 1)}>
                                        <Grid item xs={pulsante.xs}>
                                            <CustomButton
                                                label={pulsante.label}
                                                onClick={() => navigate(pulsante.path)}
                                                icon={pulsante.icon}
                                            />
                                        </Grid>
                                    </Grow>
                            }
                        )
                    }
                </Grid>
            </Grow>
        </>
    );
}

export const pulsantiera = [
    {
        path: "foto", label: "Invia una foto", subtitle: "Che sia veramente bella.",
        children: <UserFoto/>, icon: <CameraEnhance fontSize={'medium'} color={'white'}/>, xs: 12, hidden: false
    },
    {
        path: "messaggi", label: "Invia una dedica", subtitle: "E che sia simpatica.\n" +
            "Verranno proiettate!",
        children: <UserMessaggi/>, icon: <Message fontSize={'medium'} color={'white'}/>, xs: 12, hidden: false
    },
    {
        path: "domandeCartomante", label: "Domanda alla cartomante", subtitle: "Scopri il futuro.\n" +
            "Chiedi quello che vuoi alla cartomante",
        children: <UserMessaggi cartomante/>, icon: <Flare fontSize={'medium'} color={'white'}/>, xs: 12, hidden: false
    },
    /*{path: "cta", label: "Scopri di più", subtitle: "Vuoi saperne di più? Scrivi qui la tua mail per essere ricontattato!", description: "Servizi e prodotti digitali per innovare l’esperienza delle audience nel mondo degli eventi e degli spettacoli dal vivo.",
        children: <UserMessaggi cta/>, icon: <img src={clap} width={'30%'} style={{maxWidth: '100px'}}/>, xs: 12, plesh: true, hidden: false},
    {path: "messaggi", label: "Invia un Messaggio", subtitle: "Il messaggio che invierai potrà essere mostrato sul palco!",
        children: <UserMessaggi/>, icon: <Message fontSize={'large'} color={'secondary'}/>, xs: 12, hidden: false},
    {path: "wordcloud", label: "Wordcloud", title: "In una parola, per te Live Free significa…?", description: "Da tutte le parole raccolte l'Intelligenza Artificiale genererà l'immagine rappresentativa del BEA che sarà mostrata a fine evento",
        children: <UserMessaggi wordStorm/>, icon: <FilterDrama fontSize={'large'} color={'secondary'}/>, xs: 12, hidden: false},
   ,*/
]

export const Home = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const [openSections,] = useDocument(firestore.doc('test/sezioniAperte/'))
    const [pulsantiAttivi, setPulsantiAttivi] = useState(pulsantiera)

    useEffect(() => {
        if (openSections && openSections.data()) {
            const visible = openSections.data()
            if (!visible[location.pathname]) {
                navigate('/')
            }
            setPulsantiAttivi(_.filter(pulsantiera, function (o) {
                return visible[o.path]
            }))
        }
    }, [openSections])

    return (
        <Box sx={{
            width: '100%',
            height: '100vh',
            position: 'fixed',
            backgroundImage: {
                xs: `url(${sfondo})`
            },
            backgroundAttachment: 'fixed',
            backgroundSize: 'cover',
            backgroundPositionX: 'right'
        }}>
            <Routes>
                <Route index element={<HomeUI pulsantiera={pulsantiAttivi}/>}/>
                {
                    pulsantiera.map((pulsante, i) =>
                        <Route key={i} path={pulsante.path}
                               element={<WrapperInterazione title={pulsante.title || pulsante.label}
                                                            subtitle={pulsante?.subtitle}
                                                            description={pulsante.description}
                                                            children={pulsante.children} plesh={pulsante.plesh}/>}/>
                    )
                }
            </Routes>
        </Box>
    )
}