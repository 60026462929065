import React, {useEffect, useState} from "react";
import {Box, Stack, Typography} from "@mui/material";
import {COLORI, PERCENTAGE_COLOR} from "../../../config";

export const SondaggioContent = ({r}) => {
    const [results, setResults] = useState({})

    useEffect(() => {
        setResults(r)
    }, [r])

  return(
      <Box sx={{height:'100vh', width:'100%'}}>
          <Box sx={{height: `min(260px,${100/Object.keys(results).length}%)`}} >
              {results && Object.keys(results).map((r, i) =>
                  <Stack sx={{height:'100%', py:1.5}} key={i}>
                      <Box sx={{position:'relative', height:'100%'}}>
                          <Box sx={{position:'absolute', top:'50%', transform:'translate(0, -50%)', left:'2rem'}}>
                              <Typography sx={{color:PERCENTAGE_COLOR, fontWeight:'bold', fontSize:'2.2rem'}}>
                                  {results[r] === 0 ? 0+'%' : (100 * (results[r]) / Object.values(results).reduce((partialSum, a) => partialSum + a, 0)) + '%'}
                              </Typography>
                          </Box>
                          <Box sx={{
                              height: '100%',
                              width: results[r] === 0 ? 0 : 100 * (results[r]) / Object.values(results).reduce((partialSum, a) => partialSum + a, 0) + '%',
                              backgroundColor: COLORI[i],
                              transition: '1s ease',
                              borderRadius: '0 1rem 1rem 0'
                          }}></Box>
                      </Box>
                      <Box sx={{fontSize: '2.2rem', fontWeight: 'bold'}}>
                          RISPOSTA {i+1}
                      </Box>
                  </Stack>
              )}
          </Box>
      </Box>
  )
}
