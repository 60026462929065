import React, {useState} from "react";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grow,
    styled,
    TextField
} from "@mui/material";
import SuccessCheck from "./SuccessCheck";
import {motion, useMotionValue} from "framer-motion";
import tramboostTheme from "../../themes/tramboostTheme";
import useAxios from "axios-hooks";
import {API_URL, MAX_CHAR} from "../../config";
import clap from "../../assets/images/clap.png";
import logoPlesh from "../../assets/images/logo_plesh.png";
import PoweredBy from "./PoweredBy";

const CustomTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: tramboostTheme.palette.primary.main,
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: tramboostTheme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#f1ebe9',
            borderRadius: '1rem',
        },
        '&:hover fieldset': {
            borderColor: '#f1ebe9',
            borderRadius: '1rem',
        },
        '&.Mui-focused fieldset': {
            borderColor: tramboostTheme.palette.primary.main,
            borderRadius: '1rem',
        },
    },
});

export const UserMessaggi = ({wordStorm = false, cta = false, cartomante = false}) => {
    const [message, setMessage] = useState('')

    const handleSetWord = (event) => {
        showCheck && setShowCheck(false)
        // TODO: filtro messaggio trim, etc etc
        setMessage(event.target.value)
    }

    let progress = useMotionValue(90)

    const [{loading: sendingMessage, error: errorMessage}, sendMessage] = useAxios({},
        {manual: true}
    )

    const [showCheck, setShowCheck] = useState(false)

    function handleSendMessage() {
        sendMessage({
            data: {message: message},
            url: cartomante ?
                `${API_URL}domandeCartomante`
                : `${API_URL}message`,
            method: "POST"
        })
            .then((res) => console.log("sent:", res))
            .catch((err) => console.log("err:", err))
            .finally(() => {
                setMessage('')
                setShowCheck(true)
            })
    }

    return (<div>
            <Backdrop
                sx={{color: '#f1ebe9', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={sendingMessage}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Grow in={true} exit={true} timeout={600}>
                <Box display={'flex'}
                     flexDirection={'column'}
                     alignItems={"center"}
                     mt={2}
                     sx={{/*position: 'absolute', bottom: '65px', left: 0, right: 0, */textAlign: 'center'}}
                >
                    {!sendingMessage && (showCheck || errorMessage) &&
                        <Box width={'35%'}>
                            <motion.div
                                initial={{x: 0}}
                                animate={{x: 100}}
                                style={{x: progress}}
                                transition={{duration: 0.8}}
                            />
                            <SuccessCheck progress={progress}/>
                        </Box>}
                    {<Box mt={2} width={'100%'}>
                        <FormControl variant="standard" style={{width: '100%'}}>
                            <CustomTextField /*onFocus={() => setDisplay(false)} onBlur={() => setDisplay(true)}*/
                                placeholder={`${cartomante ?
                                    "Scrivi qui la tua domanda" 
                                        : "Scrivi il tuo messaggio"
                                }`}
                                id="component-simple"
                                multiline={!wordStorm && !cta}
                                minRows={!wordStorm && !cta ? 3 : 1}
                                maxRows={!wordStorm && !cta ? 3 : 1}
                                inputProps={{ maxLength: MAX_CHAR }}
                                //type={cta ? 'email' : ''}
                                value={message}
                                onChange={handleSetWord}
                                variant="outlined"/>
                            {(wordStorm && message.includes(' ')) ? <FormHelperText sx={{px: 1, fontWeight: 'bold'}}>Inviaci una sola parola, senza spazi</FormHelperText>
                            : message.length >= MAX_CHAR ? <FormHelperText sx={{px: 1, fontWeight: 'bold'}}>Massimo {MAX_CHAR} caratteri</FormHelperText> : null}
                        </FormControl>
                    </Box>}
                    {<Button sx={{mt: 3, width: '100%'}} variant={'neumorphic'} onClick={handleSendMessage}
                             disabled={!message || message.length >= MAX_CHAR || (wordStorm && message.includes(' '))}>
                        Invia
                    </Button>}
                    {cta && <Box mt={2} display={'flex'} justifyContent={'flex-end'} mr={3}>
                        <img src={clap} width={'30%'} style={{paddingLeft: '2%', maxWidth: '380px'}}/>
                    </Box>}
                </Box>
            </Grow>
        </div>
    )
}
