import React from "react";
import {AppBar, Box, Button, Container, IconButton,Menu, MenuItem, Stack, Toolbar, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {Logout} from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import {auth} from "../firebase/clientApp";

const pagine = [
    {
        testo: "Messaggi",
        link: "/control/messaggi"
    },
    {
        testo: "foto",
        link: "/control/foto"
    },
    {
        testo: "domandeCartomante",
        link: "/control/domandeCartomante"
    }
]

export const ControlLayout = ({children}) => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleLogout = (e) => {
        e.preventDefault()
        auth.signOut()
    }

    return (
        <Box>
            <AppBar position={"static"}>
                <Box sx={{px: 4}}>
                    <Toolbar>
                        <Stack sx={{width:'100%'}} direction={"row"} justifyContent={"space-between"}>
                            <Stack spacing={1} direction={"row"} sx={{display:{xs:'none', md:'flex'}}}>
                                {pagine.map(p => <Button sx={{color: 'white'}} component={Link}
                                                         to={p.link}>{p.testo}</Button>)}
                            </Stack>
                            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                    }}
                                >
                                    {pagine.map((page) => (
                                        <MenuItem key={page.testo} component={Link} to={page.link}>
                                            <Typography textAlign="center">{page.testo}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                            <IconButton onClick={handleLogout}>
                                <Logout/>
                            </IconButton>
                        </Stack>
                    </Toolbar>
                </Box>
            </AppBar>
            <Container maxWidth={'lg'} sx={{py:3}}>
                {children}
            </Container>
        </Box>
    )
}
