import CustomHeader from "./CustomHeader";
import {Box, IconButton, Typography} from "@mui/material";
import logoPlesh from "../../assets/images/logo_plesh.png";
import sfondo from "../../assets/images/tramboost/sfondo.jpeg"
import {ArrowRight, OpenInNew} from "@mui/icons-material";
import PoweredBy from "./PoweredBy";
import React from "react";
import {Deco} from "../Home";

const WrapperInterazione = ({children, plesh = false, title, subtitle, description}) => {
    return (
        <Box>
            <CustomHeader plesh={!plesh}/>
            <Box display={"flex"} flexDirection={'column'} justifyContent={"center"} alignItems={'center'}>
            <Box mt={3} paddingX={4} maxWidth={'600px'}>
                {
                    plesh ?
                        <>
                            {subtitle && <Box my={3}>
                                <Typography gutterBottom variant={'h4'} textAlign={'center'}>{subtitle}</Typography>
                            </Box>}
                            {children}
                            {plesh && <Box my={3} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <img src={logoPlesh} width={'48%'} style={{maxWidth: '150px', paddingLeft: '2%', filter: "brightness(0) invert(1) opacity(0.9)"}}/>
                                <IconButton onClick={() => window.open("https://plesh.co/", '_blank')}
                                            endIcon={<ArrowRight/>}
                                    //variant={'outlined'}
                                            size={'small'}
                                >
                                    <OpenInNew/>
                                </IconButton>
                            </Box>}
                            {description && <Typography variant={'subtitle1'} textAlign={'center'}>{description}</Typography>}
                        </>
                    : <>
                            {title && <Typography gutterBottom variant={'h2'}>{title}</Typography>}
                            {subtitle && <Box my={1}>
                                <Typography gutterBottom variant={'h5'}>{subtitle}</Typography>
                            </Box>}
                            {description && <Typography>{description}</Typography>}
                            {children}
                        </>
                }
            </Box>
            {!plesh && <PoweredBy/>}
            </Box>
        </Box>);
}

export default WrapperInterazione